import React, {useState, useEffect} from "react";
import '../index.css';
import { Link } from "react-router-dom";
import c from "../assets/data/colors";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

const Results = ({score, startGame, amountOfQuestions, category, scoreDetails}) => {

    const resetGame = () => {
        startGame()
    }

    const addZero = (num) => {
      return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    const makeDateTime = () => {
      let now = new Date();
      var strDateTime = [[addZero(now.getDate()), 
        addZero(now.getMonth() + 1), 
        now.getFullYear()].join("."), 
        [addZero(now.getHours()), 
        addZero(now.getMinutes())].join(":"), 
        now.getHours() >= 12 ? "PM" : "AM"].join(" ");
      return strDateTime;
    }

    const datetime = makeDateTime();
    const [details, setDetails] = useState(true);

    useEffect(() => {
      logEvent(analytics, 'endGame', {
        correctPerc: (parseInt((parseFloat(score.rightAnswers)/(parseFloat(amountOfQuestions)))*100)).toString(),
        category: category,
        time: score.time
      })
    },[])
 

    return (
      <div id="results" style={resultsMainStyle}>
        <div id="result" style={correctAnswersStyle}>
            {(parseInt((parseFloat(score.rightAnswers)/(parseFloat(amountOfQuestions)))*100)).toString()+"%"}
        </div>

        {details ?
        <div className="labelsGroup" style={labelsGroupStyle}>

            <div className="labels" style={labelsStyle}>
                {"POPRAWNYCH: " + score.rightAnswers}
            </div>
            <div className="labels" style={labelsStyle}>
                {"BŁĘDNYCH: " + score.wrongAnswers}
            </div>
            <div className="labels" style={labelsStyle}>
                {"BRAKUJĄCYCH: " + (parseInt(amountOfQuestions)-parseInt(score.rightAnswers)-parseInt(score.wrongAnswers)).toString()}
            </div>

            <div className="labels" style={labelsStyle}>
                {"KATEGORIA: " + category}
            </div>
            <div className="labels" style={labelsStyle}>
                {"CZAS: " + score.time}
            </div>
            <div className="labels" style={labelsStyle}>
                {"DATA: " + datetime}
            </div>
        </div> : 
        <div className="labelsGroup" style={labelsGroupStyle}>
          <div className="scrollbar scrollbar-primary" style={scoreDetailsStyle}>
          {scoreDetails.map(record => (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "2px"}} key={record[0]}>
              <div style={{paddingLeft: "0px"}} key={record[0]+"no"}>{record[0]}.&nbsp;&nbsp;{record[1]} </div>
              { (record[2] === "rightAnswers") ? <div style={{paddingRight: "0px"}} key={record[1]+"a"}> &#10003; </div> : null}
              { (record[2] === "wrongAnswers") ? <div style={{paddingRight: "0px"}} key={record[1]+"b"}> &#10007; </div> : null}
              { (record[2] === "rightAnswersQST") ? <div style={{paddingRight: "0px"}} key={record[1]+"a"}> &#10003;+? </div> : null}
              { (record[2] === "wrongAnswersQST") ? <div style={{paddingRight: "0px"}} key={record[1]+"b"}> &#10003; </div> : null}                
              { (record[2] === undefined) ? <div style={{paddingRight: "0px"}} key={record[1]+"c"}> &#8211; </div> : null}
            </div>
          ))}
          </div>
        </div>}
          
        <div style={circleLabelsGroupStyle}>

          <div
              style={circleLabelsStyle}
              onClick={() => {setDetails(!details)}}>
              ANALIZA
          </div>

          <div
              style={biggerCircleLabelsStyle}
              onClick={() => resetGame()}>
              JESZCZE RAZ
          </div>
          
          <Link to={'/game'}>
              <div
                  style={circleLabelsStyle}
                  >
                  USTAWIENIA
              </div>
          </Link>
        </div>
      </div>
    );
  }
  
  export default Results;

const resultsMainStyle ={
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: c.bluegray,
  position: "absolute",
  top: 0,
  zIndex:2000,
  overflow: "scroll",
}

const correctAnswersStyle = {
  width: "25vh",
  height: "25vh",
  background: c.bluegray,
  paddingTop: "7vh",
  marginTop: "5vh",
  marginBottom: "6vh",
  'borderRadius': "13vh",
  border: "solid 4px #FFFFFF",
  textAlign: "center",
  ...c.montserratbold,
  fontSize: "8vh",
  color: c.green,
}

const scoreDetailsStyle = {
  width: "80%",
  height: "100%",
  ...c.whitebox,
  textAlign: "left",
  ...c.montserratbold,
  'color': c.naviblue,
  overflow: "scroll",
}

const labelsStyle = {
  backgroundColor: c.white,
  border: false,
  'borderRadius': 5,
  'filter': c.shadow,
  textAlign: "center",
  ...c.montserratbold,
  'color': c.naviblue,
}

const labelsGroupStyle ={
  height: "40vh",
  background: c.bluegray,
  display: "flex",
  'flexWrap': "wrap",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-evenly",
}

const circleLabelsGroupStyle ={
  height: "18vh",
  background: c.bluegray,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-evenly",
}

const circleLabelsStyle = {
  width: "8vh",
  height: "8vh",
  backgroundColor: c.white,
  margin: 15,
  paddingTop: "3.5vh",
  border: false,
  'borderRadius': "4vh",
  'filter': c.shadow,
  textAlign: "center",
  ...c.montserratbold,
  fontSize: "1vh",
  'color': c.naviblue,
}

const biggerCircleLabelsStyle = {
  width: "11vh",
  height: "11vh",
  backgroundColor: c.white,
  paddingTop: "5vh",
  border: false,
  'borderRadius': "6vh",
  'filter': c.shadow,
  textAlign: "center",
  ...c.montserratbold,
  fontSize: "1.2vh",
  'color': c.naviblue,
}
  