const colors = {
    naviblue: "rgba(31,47,80,1)",
    white: "rgba(255,255,255,1)",
    green: 'rgba(51, 204, 103,1)',
    bluegray: 'rgba(244, 244, 247,1)',
    red: 'rgba(254, 49, 28, 1)',
    
    shadow: "drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161))",
    shadowgreen: "drop-shadow(5px 5px 6px rgba(91, 193, 175, 0.5))",
    
    whitebox: {
        'background': "rgba(255,255,255,1)",
        'filter': "drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161))",
        'border': false,
        'borderRadius': 5,
        'position': "relative",
        'textAlign': "center",
        'padding': 15,
    },
    montserratbold: {
        'fontFamily': "Montserrat",
	    'fontStyle': "normal",
	    'fontWeight': "bold",
    },
    cambaybold: {
        'fontFamily': "Cambay",
	    'fontStyle': "normal",
	    'fontWeight': "bold",
    },
    cambayregular: {
        'fontFamily': "Cambay",
	    'fontStyle': "normal",
	    'fontWeight': "regular",
    },
}

export default colors