import React, {useState, useEffect} from "react";
import dict from '../assets/data/dict.json'
import arrow from '../assets/images/arrow-down.png'
import c from '../assets/data/colors'
import { db, storage } from '../firebaseConfig';
import { collection, getDocs, query, where } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faCircleRight } from '@fortawesome/free-regular-svg-icons'
import { faPlane, faBus, faTaxi, faTrain, faShip, faHouseChimney } from '@fortawesome/free-solid-svg-icons'

const transportIcon = (transport) => {
  const dict = {
    plane: faPlane,
    bus: faBus,
    taxi: faTaxi
  }
  let ar = transport.split('+')
  return ar.map(type => {return dict[type]})
}

const TripSummary = ({tripId, neighbours, changePoint}) => {

  const [images, setImages] = useState([])
  const [trip, setTrip] = useState([])
    
  useEffect(() => {
    (async () => {
      const querySnapshotTrips = await getDocs(query(collection(db, "tripsDetails"), where("tripId", "==", parseInt(tripId))))      
      let tripsList = []
      querySnapshotTrips.forEach((doc) => {
      console.log(doc.data())
        tripsList.push(doc.data())
      });
      setTrip(tripsList[0])
    })();

    (async () => {
      const querySnapshot = await getDocs(collection(db, "images"))
      let imagesList = []
      let imagesList2 = []
      querySnapshot.forEach((doc) => {
        imagesList.push(doc.data().big_url)
      });

      for (const image of imagesList) {
        let spaceRef = ref(storage, image)
        const downloadURL = await getDownloadURL(spaceRef)
        imagesList2.push(downloadURL)
      }

      setImages(imagesList2)

    })();
  }, []);

    return (
      <div id="tripSummary" className="scrollbar scrollbar-primary" style = {{...normalInfo, overflow: "scroll"}}>
        {neighbours ? 
        <div id="navigation" style = {{fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "end", width: "100%"}}>
          <div id="next2" style = {{width: "48%", fontSize: 12, display: "flex", flexDirection: "row", alignItems: "center",  justifyContent: "center", backgroundColor: '#F4F4F7',  borderRadius: "5px"}}  onClick={() => changePoint(neighbours[1].endId)}>
          {neighbours[1].name ? <>
          <div style = {{display: "flex", flexDirection: "column"}}>
          <div style = {{fontSize: 16}}>{neighbours[1].name}</div>
          <div>{(neighbours[1].time / 60) + "h - " + neighbours[1].length / 1000 + " km"}</div>
          </div>
          <div style = {{display: "flex", flexDirection: "column"}}>
          {transportIcon(neighbours[1].type).map(icon => 
            <FontAwesomeIcon key={icon.toString()} icon={icon} style={{height: 15, marginLeft: 7}}/>  
          )}
          </div>
          </> : null}
          </div>
        </div> : null}
        <div id="tripName2">
          {trip ? trip.name : ""}
        </div>
        <div id="tripDetails2" style = {{width: "100%", fontSize: 12, display: "flex", direction: "row", flexWrap: "wrap", justifyContent: "space-evenly"}}>
          <div style = {{width: "50%"}}>
            {trip.date ? "Czas: " + trip.date : ""}
          </div>
           <div style = {{width: "50%"}}>
            {trip.distances ? "Samolot: " + trip.distances.plane + " km" : ""}
          </div>
          <div style = {{width: "50%"}}>
            {trip.time ? "Długość: " + trip.time + " dni" : ""}
          </div>
          <div style = {{width: "50%"}}>
            {trip.distances ? "Bus: " + trip.distances.bus + " km" : ""}
          </div>
          <div style = {{width: "50%"}}>
            {trip.prices ? "Ceny: " + trip.prices : ""}
          </div>
          <div style = {{width: "50%"}}>
            {trip.distances ? "Samochód: " + trip.distances.car + " km" : ""}
          </div>
        </div>
        <div id="tripMustSee">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginTop: "10px"}}>
            {"Co trzeba zobaczyć"}
          </div>
          {trip.mustsee ? trip.mustsee.map( (obj, idx) =>
            <div key ={idx} style = {{fontSize: 14}}>
              {obj ? (idx+1) + ". " + obj : ""}
             </div>)
          : null}
        </div>
        <div id="tripDescription2">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginTop: "10px"}}>
            {"Opis"}
          </div>
          <div style = {{fontSize: 14}}>
            {"Opis podróży w 3 zdaniach"}
          </div>
        </div>
        <div id="photos2">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginBottom: "15px"}}>
            {"Zdjęcia"}
        </div>
          <div style = {{fontSize: 14, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap"}}>
            {images.map(image =>
              <img key={image} src={image} style={{marginBottom: "10px", width: "40%"}} alt="photo"></img>
              )}
              {images.map(image =>
              <img key={"0"+image} src={image} style={{marginBottom: "10px", width: "40%"}} alt="photo"></img>
              )}
          </div>
        </div>
      </div>
    );
 }


export default TripSummary;

const normalInfo = {
    ...c.whitebox,
    // 'height': 525,
    'marginTop': 35,
    'marginLeft': 35,
    'zIndex': 1000,
    ...c.cambaybold,
    'color': c.naviblue,
}