import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Map from './pages/Map';
import Header from './components/Header';
import List from './pages/List';
import Game from './pages/Game';
import Comments from './pages/Comments';
import GameMap from './pages/GameMap';
import BlogMap from './pages/BlogMap';
import TripMap from './pages/TripMap';
import {Helmet} from 'react-helmet'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

class App extends React.Component {
  
  render() {
    return (
      <div>
        <Helmet>
          <title>Geo Quiz</title>
          <meta name="description" content="GeoQuiz to aplikacja do nauki mapy Polski" />
        </Helmet>
        <Header />
        <Router>
          <Switch>
            <Route
              path='/'
              exact component = {Map}
            />
            <Route
              path='/map'
              exact component = {Map}
            />
            <Route
              path='/list'
              exact component={List}
            />
            <Route
              path='/blog'
              exact component={BlogMap}
            />
            <Route
              path='/trip/:trip'
              exact component={TripMap}
            />
            <Route
              path='/game'
              exact component={Game}
            />
            <Route
              path='/comments'
              exact component={Comments}
            />
            <Route
              path='/game/:difficultyLevel/:answerTime/:amountOfQuestions/:category/:details'
              exact component={GameMap}
            />
            <Route
              path='*'
              component={() => "404 NOT FOUND"}
            />
            {/* should be some component NoMatch */}
          </Switch>
        </Router>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
