import React, {useState} from "react";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import rvr from '../assets/geodata/rvr.json';
import cts from '../assets/geodata/cts.json';
import lks from '../assets/geodata/lks.json';
import mnt from '../assets/geodata/mnt.json';
import prk from '../assets/geodata/prk.json';
import uns from '../assets/geodata/uns.json';
import mzr from '../assets/geodata/mzr.json';
import lnd from '../assets/geodata/lnd.json';
import glg from '../assets/geodata/glg.json';
import dst from '../assets/geodata/dst.json';
import {AccordionFull, MenuItem, Arrow} from '../components/ListComponents';
import c from "../assets/data/colors";

const List = () => {
  
    const [selected, setSelected] = useState("góry")
    const list = [
        { name: 'góry' },
        { name: 'jeziora' },
        { name: 'krainy' },
        { name: 'mazury' },
        { name: 'miasta' },
        { name: 'parki' },
        { name: 'rzeki' },
        { name: 'unesco' },
        { name: 'województwa' },
        { name: 'zagłębia' }
    ]

    const [displayData, setDisplayData] = useState(mnt)
    const data = {
        góry: mnt,
        jeziora: lks,
        krainy: lnd,
        mazury: mzr,
        miasta: cts,
        parki: prk,
        rzeki: rvr,
        unesco: uns,
        województwa: dst,
        zagłębia: glg
    }

    const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' })
    const ArrowRight = Arrow({ text: '>', className: 'arrow-next' })

    const createMenu = (list, selected) =>
      list.map(el => {
          const {name} = el
          return <MenuItem text={name} key={name} selected={selected}/>
      })

    const menuItems = createMenu(list, selected)

    return (
        <>
            <div style={parameterBoxStyle}>
                <ScrollMenu
                data={menuItems}
                arrowLeft={ArrowLeft}
                arrowRight={ArrowRight}
                selected={selected}
                onSelect={(e) => {setSelected(e); setDisplayData(data[e])}}
                />
            </div>
            <div style={parameterBoxStyle}>
                <AccordionFull data={displayData}/>
            </div>
        </>
    );
}

const parameterBoxStyle = {
    ...c.whitebox,
    'width': "90%",
    'margin': "auto",
    'marginTop': 15,  
    'marginBottom': 15, 
}

export default List;