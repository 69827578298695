import React, {useState, useEffect} from "react";
import logo from '../assets/images/logo.png';
import trips from '../assets/geodata/countriesTrips.json';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, storage } from '../firebaseConfig';

const Header = () => {

const [trips, setTrips] = useState([])
    
  useEffect(() => {
    (async () => {
      const querySnapshotTrips = await getDocs(collection(db, "tripsDetails"))      
      let tripsList = []
      querySnapshotTrips.forEach((doc) => {
      console.log(doc.data())
        tripsList.push(doc.data())
      });
      setTrips(tripsList)
      console.log(tripsList)
    })();
}, []);

      return (
        <div className="navbar navbar-dark navbar-expand-md"  style={{"backgroundColor": "#1F2F50"}} role="navigation">
            <a className="navbar-brand" style={{'fontFamily': "Montserrat", 'fontStyle': "normal", 'fontWeight': "bold", 'fontSize': 22}} href="/map">
              <img src={logo} width="30" alt="logo" style={{"marginRight": 16}}/>
              GeoQuiz&Blog
            </a>
        
            <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#mainmenu" aria-controls="mainmenu" aria-expanded="false" aria-label="Menu">
                <span className="navbar-toggler-icon"></span>
            </button>
            
            <div className="collapse navbar-collapse" id="mainmenu">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/map">Atlas</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/list">Spis obiektów</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Blog podróżniczy (beta)
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/blog">Mapa</a>
                        <div className="dropdown-divider"></div>
                            {trips.map(trp => <a key={trp.tripId} className="dropdown-item" href={"/trip/" + trp.tripId}>{trp.name}</a>)}
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/game">Gra</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/comments">Uwagi</a>
                    </li>
                </ul>
            </div>

            <a className="navbar-text" style={{'fontFamily': "Montserrat", 'fontStyle': "normal", 'fontWeight': 500, 'fontSize': 16}}href="/map">
              By Bartosz Wiktorzak
            </a>
        </div>
      );
  }

export default Header;