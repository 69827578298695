import React, {useState, useEffect, useRef} from "react";
import L from "leaflet";
import gsap from 'gsap';
import * as FS from '../plugins/Leaflet.fullscreen/dist/Leaflet.fullscreen.js';
import * as IL from '../plugins/LayersControl/iconLayers.js';
import autoIcon from '../plugins/LayersControl/icons/auto.png';
import sattIcon from '../plugins/LayersControl/icons/satt.png';
import hipsIcon from '../plugins/LayersControl/icons/hipso.png';
import osmIcon from '../plugins/LayersControl/icons/osm.png';
import vst from '../assets/geodata/countriesTrips.json';
import CountrySummary from "../components/CountrySummary.js";

//function contains layer name, data, style
function Issue(name, namePL, id, source, qst, style) {
  this.name = name;
  this.namePL = namePL
  this.id = id;
  this.source = source;
  this.layer = L.geoJson(this.source,
    {filter: function(feature, layer) {return feature.properties.visited == "true";}}
  )
  this.qst = qst;
  this.style = style;
  this.setStyle = function() {return this.layer.setStyle(this.style)};
}

const BlogMap = () => {

  //wrapper used for gsap
  const wrapper = useRef(null);

  const [name, setName] = useState(undefined)
  const [infoExtended, setInfoExtended] = useState(false)

  useEffect(() => {

    //animations
    const countrySummary = wrapper.current.children.countrySummary
    //const infoExtended = wrapper.current.children.question

      // gsap.set([countrySummary/*, infoExtended*/], {autoAlpha: 0});

      // const tl = gsap.timeline({defaults: {ease: 'power3.inOut'}})

      // tl.fromTo(countrySummary, {x: '-=50'}, {duration: 2, x: '+=50', autoAlpha: 1})

    

    //map init
    let mymap = L.map('map').setView([57.947609, 19.157056], 6, {zoomControl: true });
    //maps max&min zoom levels
    mymap.options.minZoom = 3;
    mymap.options.maxZoom = 10;  
    mymap.setMaxBounds([[-89.000, -179.000], [73.000, 179.000]])

    //set first basemap - init one
    //var OSM = L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvpr8pv1sk719pahw7y64h7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA').addTo(mymap)
    //basemaps which can be choosen
    var providers = {};
    providers['Default'] = {
        title: 'auto',
        icon: autoIcon,
        layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvpr8pv1sk719pahw7y64h7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
            maxZoom: 20,
        })
    };
    providers['Hipso'] = {
      title: 'hipso',
      icon: hipsIcon,
      layer: L.tileLayer.wms('https://ows.terrestris.de/osm/service?', {
        layers: 'TOPO-WMS'
      })
    };providers['Sattelite'] = {
      title: 'satka',
      icon: sattIcon,
      layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvsgxi02g4d19lli0o110t2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
          maxZoom: 20,
      })
    };
    providers['OSM'] = {
      title: 'osm',
      icon: osmIcon,
      layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
      })
    };


    //set basemap control - insert basemaps and add control
    var layers = [];
    for (let providerId in providers) {
      layers.push(providers[providerId]);
    }

    //insert needed layers with data -->add issue 1/3
    var VST = new Issue("VST", "Odwiedzone", "V", vst, 1, {weight : 2, color : '#33CC67'}); VST.setStyle();

    VST.layer.addTo(mymap);
    
    //fullscreen control
    mymap.addControl(new L.Control.Fullscreen({position: 'bottomright'}));

    //zoom control
    mymap.zoomControl.setPosition('bottomright');


    mymap.addControl(new L.control.IconLayers(layers));

    //set suitable zoom level
    mymap.fitBounds(VST.layer.getBounds());

    //big polygons need normal click apart from alomstOver
    VST.layer.on('click', onClicker);

    function onClicker(e) {
      let object = e.layer.feature.properties;
      setName(object);
    }
  }, []);

  const changeInfoExtended = (condition) => {
    setInfoExtended(condition)
  }

  return (
    <div ref={wrapper} id="map">
      {name ? <CountrySummary information={name} infoExtended={infoExtended}  setInfoExtended={(e) => changeInfoExtended(e)}/> : null}
    </div>
  );
}

export default BlogMap;
