import React, {useState, useRef, useEffect} from "react";

const ProgressBar = ({answerTime}) => {
  
  const [time, setTime] = useState(0);
  const [barLength, setBarLength] = useState("0%");

  useEffect(() => {
    let interval;
    let correction = 100 + (100000 / answerTime)

    interval = setInterval(() => {
      setTime(time + correction);
      setBarLength(((time + correction) / answerTime * 100) + "%")
    }, 100);

    return () => {clearInterval(interval); }
  }, [time]);
  
  return (
    <div className="progress" style={{height: 5}}>
      {time / answerTime < 0.9 ? <div className="progress-bar bg-success" role="progressbar" style={{width: barLength, zIndex: 1000}}/>
      : <div className="progress-bar bg-danger" role="progressbar" style={{width: barLength, zIndex: 1000}}/> }
    </div>
  )
}

const Timer = ({isActive, reset, passTime}) => {
  
  const [minuteS, setMinutes] = useState(0);
  const [secondS, setSeconds] = useState(0);
  const [milisecondS, setMiliseconds] = useState(0);
  
  const miliseconds = useRef(0)
  const seconds = useRef(0)
  const minutes = useRef(0)

  const addZero = (num) => {
    return (num >= 0 && num < 10) ? "0" + num : num + "";
  }

  useEffect(() => {
    let interval;

    if (reset) {
      miliseconds.current = 0;
      seconds.current = 0;
      minutes.current = 0;
      setMiliseconds(miliseconds.current);
      setSeconds(seconds.current);
      setMinutes(minutes.current);
    }

    if (isActive) {
      interval = setInterval(() => {
        miliseconds.current = miliseconds.current + 1;
        setMiliseconds(miliseconds.current);
        if (miliseconds.current >= 10) {
          seconds.current = seconds.current + 1;
          setSeconds(seconds.current);
          miliseconds.current = 0;
          setMiliseconds(miliseconds.current);
        }
        if (seconds.current >= 60) {
          minutes.current = minutes.current + 1;
          setMinutes(minutes.current);
          seconds.current = 0;
          setSeconds(seconds.current);
        }
      }, 100);
    } else if (!isActive && seconds.current !== 0) {
      clearInterval(interval);
      passTime(addZero(minutes.current).toString() + ":" + addZero(seconds.current).toString() + "." + miliseconds.current.toString())
    }
    return () => clearInterval(interval);
  }, [isActive, reset, miliseconds.current, seconds.current, minutes.current]);
  
  return (
    <div id="timer">
      {addZero(minuteS)+":"+addZero(secondS)+"."+milisecondS}
    </div>
  )
}

const Score = ({currentWordNumber, amountOfQuestions}) => {

  return (
    <div id="score">
      {currentWordNumber+"/"+amountOfQuestions}
    </div>
  );
}

export {ProgressBar, Timer, Score}