import React, {useState, useRef, useEffect} from "react";
import L from "leaflet";
import gsap from 'gsap';
import Bootstrap from 'bootstrap';
import * as FS from '../plugins/Leaflet.fullscreen/dist/Leaflet.fullscreen.js';
import autoIcon from '../plugins/LayersControl/icons/auto.png';
import sattIcon from '../plugins/LayersControl/icons/satt.png';
import hipsIcon from '../plugins/LayersControl/icons/hipso.png';
import osmIcon from '../plugins/LayersControl/icons/osm.png';
import rvr from '../assets/geodata/rvr.json';
import cts from '../assets/geodata/cts.json';
import lks from '../assets/geodata/lks.json';
import mnt from '../assets/geodata/mnt.json';
import prk from '../assets/geodata/prk.json';
import uns from '../assets/geodata/uns.json';
import mzr from '../assets/geodata/mzr.json';
import lnd from '../assets/geodata/lnd.json';
import glg from '../assets/geodata/glg.json';
import dst from '../assets/geodata/dst.json';
import cnt from '../assets/geodata/cnt.json';
import wrd from '../assets/geodata/wrld.json'; //add 1/10
import wct from '../assets/geodata/wcts.json'; //add 2/10
import eur from '../assets/geodata/eur.json'; //add 2/10
import qst from '../assets/data/questions.json';
import WORLDIcon from '../assets/images/placeholder.png'; //add 3/10
import WCTIcon from '../assets/images/landscapes/city.png';
import CityIcon from '../assets/images/landscapes/cityscape.png';
import UnescoIcon from '../assets/images/landscapes/castle.png';
import Question from '../components/Question';
import {InfoGame} from '../components/Info';
import Results from '../components/Results';
import {ProgressBar, Timer, Score} from '../components/GameMapComponents'
import c from "../assets/data/colors";

//function contains layer name, data, style
function Issue(name, namePL, id, source, qst, style, lvlDiff, isPoint = false, isLargePolygon = false) {
  this.name = name;
  this.namePL = namePL
  this.id = id;
  this.source = source;
  this.layer = L.geoJson(this.source, {pointToLayer: function PToL(feature, latlng) {return L.marker(latlng,style)}, filter: function FBL(feature) {return feature.properties.lvlDiff <= lvlDiff}});
  this.qst = qst;
  this.style = style;
  this.isPoint = isPoint;
  this.isLargePolygon = isLargePolygon
  this.setStyle = function(newStyle = this.style) {return this.layer.setStyle(newStyle)};
}

const createDA = (activeIssueLayer, difficultyLevel, arraySize) => { // tworzy tablice losowo ustawionych nazw

  let end, draw, drawNo, drawCount = 0, diffLevel;
  let drawArray = [];

  if (difficultyLevel === "easy") {diffLevel = 0}
  else if (difficultyLevel === "medium") {diffLevel = 1}
  else if (difficultyLevel === "difficult") {diffLevel = 2}

  if (parseInt(arraySize) !== 10) {
    arraySize = 0
    for (let i in activeIssueLayer.features) {
      if (activeIssueLayer.features[i].properties.lvlDiff <= diffLevel) {
        arraySize++;
      }
    }
  }

  for (let i = 0; i < arraySize; i++) {
    do {
      drawNo = Math.floor(Math.random()*Object.keys(activeIssueLayer.features).length)
      draw = [activeIssueLayer.features[drawNo].properties.name, activeIssueLayer.features[drawNo].properties.lvlDiff];  
      end = true;
      for (let j = 0; j <= drawCount; j++) {
        if (draw[0] === drawArray[j])
          end = false;
      }
      if (draw[1] > diffLevel) {
        end = false
      }
      if (end === true)	{
        drawArray[drawCount] = draw[0];
        drawCount++;
      }
    } while(end !== true);
  }
  
  return drawArray
}


const GameMap = ({match}) => {

  //wrapper used for gsap
  const wrapper = useRef(null);

  //parameters defined game mode
  const [parameters, setParameters] = useState({
    difficultyLevel: match.params.difficultyLevel,
    answerTime: parseInt(match.params.answerTime) * 1000,
    amountOfQuestions: match.params.amountOfQuestions,
    category: match.params.category,
    details: match.params.details,
  });

  //score of one game
  const [score, setScore] = useState({
    rightAnswers: 0,
    wrongAnswers: 0,
    time: "",
  });

  const scoreDetails = useRef([]);

  //visibility of  scoreBox
  const [results, setResults] = useState(false);

  //variables to questionBox
  const [answers, setAnswers] = useState([]);
  const [question, setQuestion] = useState([]);

  //visibility of questionBox
  const [questionComp, setQuestionComp] = useState(false);

  //infoBox 
  const [infoWord, setInfoWord] = useState("ZACZYNAMY!");
  const [infoStyle, setInfoStyle] = useState("normal");

  const [progressStart, setProgressStart] = useState(false)
  
  //gameStarted - possibility to click object
  const [gameStartedHook, setGameStarted] = useState(false);
  const [timeReset, setTimeReset] = useState(false)

  const gameStartedRef = useRef(false)
  
  const interCountdown = useRef(null)

  const currentWordNumber = useRef(-1)
  const drawArray = useRef([])

  const activeIssue = useRef(null)
  

  const [currentWordNumberHook, setCurrentWordNumber] = useState(0);
  const [drawArrayHook, setDrawArray] = useState([]);
  var currentWord = "cW"


  const answerQuestion = (rightOrWrong) => {
    score[rightOrWrong] = score[rightOrWrong] + 1
    scoreDetails.current[currentWordNumber.current].push(rightOrWrong+"QST")
    setQuestionComp(false)
    setTimeout(() => {preCountdown()}, 500);
  }
  
  const startGame = () => {
    
    console.log("startGame")
    setInfoWord("")
    setTimeReset(true)
    setResults(false)
    scoreDetails.current = []
    score.rightAnswers = 0
    score.wrongAnswers = 0
    score.time = ""
    drawArray.current = createDA(activeIssue.current.source, parameters.difficultyLevel, parameters.amountOfQuestions)
    parameters.amountOfQuestions = drawArray.current.length
    setDrawArray(drawArray)
    gameStartedRef.current = false
    currentWordNumber.current = -1
    setCurrentWordNumber(currentWordNumber.current+1)
    interCountdown.current = null  
    setTimeout(() => preCountdown(), 2000);
  }

  const preCountdown = () => {
    console.log("preCountdown")
    gameStartedRef.current = true;
    setTimeReset(false)
    setGameStarted(true)
    currentWordNumber.current = currentWordNumber.current + 1;
    setCurrentWordNumber(currentWordNumber.current+1)
    if (currentWordNumber.current < parameters.amountOfQuestions) { 
      currentWord = drawArray.current[currentWordNumber.current];
      setInfoWord(currentWord)
      setInfoStyle("normal")
      scoreDetails.current.push([currentWordNumber.current+1, currentWord])
      setProgressStart(true);
      interCountdown.current = setInterval(() => {singleCountdown()}, parameters.answerTime);
    }
    else {
      gameStartedRef.current = false;
      console.log("stop Pre")
      setGameStarted(false)
      setProgressStart(false)
      setResults(true)
      setInfoWord("KONIEC-preCountdown")
    }
  }

  const singleCountdown = () => {
    console.log("singleCountdown")
    currentWordNumber.current = currentWordNumber.current+1;
    setCurrentWordNumber(currentWordNumber.current+1)
    if (currentWordNumber.current < parameters.amountOfQuestions) {
      currentWord = drawArray.current[currentWordNumber.current]
      setInfoWord(currentWord)
      setInfoStyle("normal")
      scoreDetails.current.push([currentWordNumber.current+1, currentWord])
      setProgressStart(false)
      setProgressStart(true);
    }
    else {
      clearInterval(interCountdown.current)  
      gameStartedRef.current = false;
      console.log("stop")
      setGameStarted(false)
      setProgressStart(false)
      setResults(true)
      setInfoWord("KONIEC-singleCountdown")
    }
  }

  const createQuestion = (currentWord) => {

    let end, draw, qstGroup = activeIssue.current.qst, qstNr = Math.floor(Math.random()*qst.group[qstGroup].question.length);
    let correctAnswer ="A", secondAnswer="B", thirdAnswer="C"
    let layerArray = activeIssue.current.source.features;

    let question = qst.group[qstGroup].question[qstNr].name;
    let attribute = qst.group[qstGroup].question[qstNr].attribute;

    for (var i = 0; i < Object.keys(layerArray).length; i++) { 
      if (layerArray[i].properties.name === currentWord)
        correctAnswer = layerArray[i].properties[attribute];
    }

    do {
      end = false;
      draw = qst.group[qstGroup].question[qstNr].example[Math.floor(Math.random()*Object.keys(qst.group[qstGroup].question[qstNr].example).length)]
      if (draw !== correctAnswer) {
        secondAnswer = draw;
      }
      draw = qst.group[qstGroup].question[qstNr].example[Math.floor(Math.random()*Object.keys(qst.group[qstGroup].question[qstNr].example).length)]
      if (draw !== correctAnswer && draw !== secondAnswer) {
        thirdAnswer = draw;
        end = true;
      }
    } while (end !== true); 
   return [question, correctAnswer, secondAnswer, thirdAnswer]
  }

  const detailsMode = (currentWord) => {
    console.log("detailsMode!")
    let QST  = createQuestion(currentWord)
    let question = QST[0], correctAnswer = QST[1], secondAnswer = QST[2], thirdAnswer = QST[3];

    let no = Math.floor(Math.random()*3), answers = [];
    if (no === 0) answers = [correctAnswer, secondAnswer, thirdAnswer, 0];
    else if (no === 1) answers = [secondAnswer, correctAnswer, thirdAnswer, 1];
    else if (no === 2) answers = [secondAnswer, thirdAnswer, correctAnswer, 2];

    setAnswers(answers)
    setQuestion([question, currentWord])
    setQuestionComp(true)

  }

  let mymap = undefined//, activeIssue = undefined
  let preObject = null, object = null;

  useEffect(() => {

    //animations
    const info = wrapper.current.children.info
    //const question = wrapper.current.children.question

    gsap.set([info/*, question*/], {autoAlpha: 0});

    const tl = gsap.timeline({defaults: {ease: 'power3.inOut'}})

    tl.fromTo(info, {y: '-=50'}, {duration: 2, y: '+=50', autoAlpha: 1})
    
    //map init
    mymap = L.map('map', { zoomControl: false }).setView([57.947609, 19.157056], 6);
    
    mymap.doubleClickZoom.disable(); 

    //maps max&min zoom levels
    //mymap.options.minZoom = 5;  //add 4/10
    mymap.options.maxZoom = 10;
    //mymap.setMaxBounds([[46.000, 11.000], [57.000, 27.000]])  //add 5/10

    //basemaps which can be choosen
    var providers = {};
    providers['Default'] = {
        title: 'auto',
        icon: autoIcon,
        layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvpr8pv1sk719pahw7y64h7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
            maxZoom: 20,
        })
    };
    providers['Hipso'] = {
      title: 'hipso',
      icon: hipsIcon,
      layer: L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
        layers: 'TOPO-WMS'
      })
    };providers['Sattelite'] = {
      title: 'satka',
      icon: sattIcon,
      layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvsgxi02g4d19lli0o110t2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
          maxZoom: 20,
      })
    };
    providers['OSM'] = {
      title: 'osm',
      icon: osmIcon,
      layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
      })
    };

    //set basemap control - insert basemaps and add cntrol
    var layers = []; 
    for (let providerId in providers) {
      layers.push(providers[providerId]);
    }
    mymap.addControl(new L.control.IconLayers(layers));

    //layers features
    let City = L.icon({
      iconUrl: CityIcon,
      iconSize:     [30, 30],
      iconAnchor:   [20, 20],
      popupAnchor:  [-30, -76]
    });

    let UNESCO = L.icon({
      iconUrl: UnescoIcon,
      iconSize:     [30, 30],
      iconAnchor:   [20, 20],
      popupAnchor:  [-30, -76]
    });

    let WORLD = L.icon({ //add 6/10
      iconUrl: WORLDIcon,
      iconSize:     [30, 30],
      iconAnchor:   [20, 20],
      popupAnchor:  [-30, -76]
    });

    let WCTS = L.icon({ //add 6/10
      iconUrl: WCTIcon,
      iconSize:     [30, 30],
      iconAnchor:   [20, 20],
      popupAnchor:  [-30, -76]
    });

    //define lvl of the game
    let diffLevel = 0
    if (parameters.difficultyLevel === "easy") {diffLevel = 0}
    else if (parameters.difficultyLevel === "medium") {diffLevel = 1}
    else if (parameters.difficultyLevel === "difficult") {diffLevel = 2}

    //insert needed layers with data -->add issue 1/3
    var CTS = new Issue("CTS", "Miasta", "C", cts, 0, {icon: City}, diffLevel, true)  
    var DST = new Issue("DST", "Województwa", "D", dst, 1, {weight : 2, color : '#f7be5c'}, diffLevel, false, true); DST.setStyle();
    var GLG = new Issue("GLG", "Zagłębia", "G", glg, 2, {weight : 2, color : '#a83254'}, diffLevel, false, true); GLG.setStyle();
    var LKS = new Issue("LKS", "Jeziora", "L", lks, 3, {weight : 2, color : '#224fa3'}, diffLevel); LKS.setStyle();
    var MNT = new Issue("MNT", "Góry", "M", mnt, 4, {weight : 2, color : '#6b4738'}, diffLevel, false, true); MNT.setStyle();  
    var LND = new Issue("LND", "Krainy", "N", lnd, 9, {weight : 2, color : '#38636b'}, diffLevel, false, true); LND.setStyle();
    var PRK = new Issue("PRK", "Parki", "P", prk, 5, {weight : 2, color : '#386b3a'}, diffLevel); PRK.setStyle();
    var RVR = new Issue("RVR", "Rzeki", "R", rvr, 6, {weight : 2, color : '#4dc3ff'}, diffLevel); RVR.setStyle();
    var UNS = new Issue("UNS", "UNESCO", "U", uns, 7, {icon: UNESCO}, diffLevel, true);
    var MZR = new Issue("MZR", "Mazury", "Z", mzr, 8, {weight : 2, color : '#6a386b'}, diffLevel); MZR.setStyle();
    var WRD = new Issue("WRD", "Świat", "W", wrd, 10, {icon: WORLD}, diffLevel, true); //add 7/10
    var WCT = new Issue("WCT", "Miasta Świata", "T", wct, 11, {icon: WCTS}, diffLevel, true); //add 8/10
    var EUR = new Issue("EUR", "Europa", "E", eur, 12, {icon: WORLD}, diffLevel, true); //add 8/10

    //push Issues to array -->add issue 2/3
    var issues = [];
    issues.push(CTS)
    issues.push(DST)
    issues.push(GLG)
    issues.push(LKS)
    issues.push(MNT)
    issues.push(LND)
    issues.push(PRK) 
    issues.push(RVR)
    issues.push(UNS)
    issues.push(MZR)
    issues.push(WRD) //add 9/10
    issues.push(WCT) //add 10/10
    issues.push(EUR) //add 10/10

    const onClick = (e) => {

      //gsap.fromTo(question, {x: '-=50'}, {duration: 0.5, x: '+=50', autoAlpha: 1}, '-=1')    
      
      if (gameStartedRef.current === true) {

        currentWord = drawArray.current[currentWordNumber.current]

        object = e.layer.feature.properties.name;        
        if (object === currentWord && object === preObject) {
          clearInterval(interCountdown.current);
          gameStartedRef.current = false;
          setGameStarted(false)
          setInfoWord("DOBRZE!");
          setInfoStyle("right")
          activeIssue.current.setStyle();
          if (!activeIssue.current.isPoint) {
            e.layer.setStyle({weight : 5, color : "#00ed00",});
          }
          
          setProgressStart(false)
          let timer = null;
          
          if (parameters.details === "yes" && Object.keys(activeIssue.current.source.features[0].properties).length > 3) {
            timer = setTimeout(() => {detailsMode(currentWord); activeIssue.current.setStyle(); preObject = null;}, 500);}    
          else {
            score.rightAnswers = score.rightAnswers + 1
            scoreDetails.current[currentWordNumber.current].push("rightAnswers")
            timer = setTimeout(() => {
              preCountdown();
              activeIssue.current.setStyle();
              preObject = null;
              if (activeIssue.current.isPoint) {
                activeIssue.current.layer.eachLayer(function(layer) { let icon = layer.options.icon
                  icon.options.iconSize = [30, 30]
                  layer.setIcon(icon);})
              }
            }, 1000);         
          }
        }
        else if (object !== currentWord && object === preObject){
          clearInterval(interCountdown.current);
          gameStartedRef.current = false;
          setGameStarted(false)
          setInfoWord("ŹLE! To " + object);
          setInfoStyle("wrong")
          activeIssue.current.setStyle();
          if (!activeIssue.current.isPoint) {
            e.layer.setStyle({weight : 5, color : "#fe311c",});
          }
          score.wrongAnswers = score.wrongAnswers + 1
          scoreDetails.current[currentWordNumber.current].push("wrongAnswers")
          
          setProgressStart(false)
          
          /*   highlight correct object */  
          activeIssue.current.layer.eachLayer(function(layer) {
            if(layer.feature.properties.name === currentWord) {
              if (!activeIssue.current.isPoint) {
                layer.setStyle({weight : 5, color: 'rgb(0, 237, 0)'})
              }
            }
          }) 

          setTimeout(() => {
            preCountdown();
            activeIssue.current.setStyle();
            preObject = null;
            if (activeIssue.current.isPoint) {
              activeIssue.current.layer.eachLayer(function(layer) { let icon = layer.options.icon
                icon.options.iconSize = [30, 30]
                layer.setIcon(icon);
              })
            }
          }, 1000);
        }

        if (!activeIssue.current.isPoint && e.layer.feature.properties.name !== preObject) {
          activeIssue.current.setStyle();
          e.layer.setStyle({weight : 5});       
        }
        if (activeIssue.current.isPoint) {
          activeIssue.current.layer.eachLayer(function(layer) { let icon = layer.options.icon
            icon.options.iconSize = [30, 30]
            layer.setIcon(icon);
          })
          let icon = e.layer.options.icon
          icon.options.iconSize = [45, 45]
          e.layer.setIcon(icon);
        }
        preObject = e.layer.feature.properties.name;
      }
    };

    //find active layer
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].name === parameters.category) {
        activeIssue.current = issues[i]
      }
    }
    
    //set suitable zoom level
    mymap.fitBounds(activeIssue.current.layer.getBounds());

    
    activeIssue.current.layer.addTo(mymap)

    if (activeIssue.current.isLargePolygon) {
      activeIssue.current.layer.on('click', function(e) { onClick(e); });
    } else {
      //almostOver 
      mymap.almostOver.addLayer(activeIssue.current.layer);
      mymap.on('almost:click', function (e) { onClick(e); })
    }
    

    //fullscreen control
    mymap.addControl(new L.Control.Fullscreen({position: 'bottomright'}));

    new L.Control.Zoom({ position: 'bottomright' }).addTo(mymap)

    //-------------------------

    var contour = L.geoJson(cnt).addTo(mymap);
    contour.setStyle({weight : 4, color : '#000000', fillOpacity: 0})
    contour.bringToBack()
    
    startGame()
  }, []);

  const passTime = (time) => {
    score["time"] = time
  }
 
  return (
    <div ref={wrapper} id="map">
      {progressStart ? <ProgressBar answerTime={parameters.answerTime}/> : null}
      <div style={timerScoreStyle} className="timerScore">
        <Timer isActive={gameStartedHook} reset={timeReset} passTime={(e) => passTime(e)}/>
        <Score currentWordNumber={currentWordNumberHook} amountOfQuestions={parameters.amountOfQuestions}/>
      </div>  
      <InfoGame infoWord={infoWord} style={infoStyle}/>
      {results ? <Results score={score} amountOfQuestions={parameters.amountOfQuestions} category={activeIssue.current.namePL} startGame={() => startGame()} scoreDetails={scoreDetails.current}/> : null}
      {questionComp ? <Question question={question} answers={answers} answerQuestion={(e) => answerQuestion(e)}/> : null}
    </div> 
    );
}

export default GameMap;

const timerScoreStyle = {
  'height': 60,
  'borderRadius': "30px 0px 0px 30px",
  'position': "absolute",
  'right': 0,
  'background': c.white,
  'filter': c.shadow,
  'zIndex': 1000}
