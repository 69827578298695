import React, {useState, useEffect, useRef} from "react";
import L from "leaflet";
import gsap from 'gsap';
import * as FS from '../plugins/Leaflet.fullscreen/dist/Leaflet.fullscreen.js';
import * as IL from '../plugins/LayersControl/iconLayers.js';
import autoIcon from '../plugins/LayersControl/icons/auto.png';
import sattIcon from '../plugins/LayersControl/icons/satt.png';
import hipsIcon from '../plugins/LayersControl/icons/hipso.png';
import osmIcon from '../plugins/LayersControl/icons/osm.png';
import TripSummary from "../components/TripSummary.js";
import TripPoint from "../components/TripPoint.js";
import trp from '../assets/geodata/trips.json';
import jrn from '../assets/geodata/journeys.json';

import CityIcon from '../assets/images/landscapes/cityscape.png';

//function contains layer name, data, style
function Issue(name, namePL, id, source, qst, style, trip) {
  this.name = name;
  this.namePL = namePL
  this.id = id;
  this.source = source;
  this.layer = L.geoJson(this.source,
    {
      pointToLayer: function PToL(feature, latlng) {return L.marker(latlng,style)}, 
      filter: function(feature, layer) {return feature.properties.tripNo == trip;}
    }
  )
  this.qst = qst;
  this.style = style;
  this.setStyle = function() {return this.layer.setStyle(this.style)};
  this.trip = trip;
}

const TripMap = ({match}) => {

  //wrapper used for gsap
  const wrapper = useRef(null);
  const mymap = useRef(null);

  const [mapInstance, setMapInstance] = useState(null);
  const [point, setPoint] = useState(undefined)
  const [neighbours, setNeighbours] = useState(undefined)
  

  useEffect(() => {

    //animations
    const tripSummary = wrapper.current.children.tripSummary

    gsap.set([tripSummary/*, infoExtended*/], {autoAlpha: 0});

    const tl = gsap.timeline({defaults: {ease: 'power3.inOut'}})

    tl.fromTo(tripSummary, {x: '-=50'}, {duration: 2, x: '+=50', autoAlpha: 1})

    //map init
    mymap.current = L.map('map').setView([57.947609, 19.157056], 6, {zoomControl: true });
    //maps max&min zoom levels
    mymap.current.options.minZoom = 3;
    mymap.current.options.maxZoom = 10;  
    mymap.current.setMaxBounds([[-89.000, -179.000], [73.000, 179.000]])

    //set first basemap - init one
    //var OSM = L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvpr8pv1sk719pahw7y64h7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA').addTo(mymap)
    //basemaps which can be choosen
    var providers = {};
    providers['Default'] = {
        title: 'auto',
        icon: autoIcon,
        layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20,
        })
    };
    providers['Hipso'] = {
      title: 'hipso',
      icon: hipsIcon,
      layer: L.tileLayer.wms('https://ows.terrestris.de/osm/service?', {
        layers: 'TOPO-WMS'
      })
    };providers['Sattelite'] = {
      title: 'satka',
      icon: sattIcon,
      layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvsgxi02g4d19lli0o110t2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
          maxZoom: 20,
      })
    };
    providers['OSM'] = {
      title: 'osm',
      icon: osmIcon,
      layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
      })
    };


    //set basemap control - insert basemaps and add control
    var layers = [];
    for (let providerId in providers) {
      layers.push(providers[providerId]);
    }

    let City = L.icon({
      iconUrl: CityIcon,
      iconSize:     [20, 20],
      iconAnchor:   [10, 10],
      popupAnchor:  [-30, -76]
    });

    //insert needed layers with data -->add issue 1/3
    var TRP = new Issue("TRP", "Wycieczka", "T", trp, 1, {icon: City},  match.params.trip);
    var JRN = new Issue("JRN", "Trasa", "J", jrn, 6, {weight : 4, color : '#D05237', dashArray: "15 15"}, match.params.trip); JRN.setStyle();

    TRP.layer.addTo(mymap.current);
    JRN.layer.addTo(mymap.current);
    
    //fullscreen control
    mymap.current.addControl(new L.Control.Fullscreen({position: 'bottomright'}));

    //zoom control
    mymap.current.zoomControl.setPosition('bottomright');


    mymap.current.addControl(new L.control.IconLayers(layers));

    //set suitable zoom level
    mymap.current.fitBounds(TRP.layer.getBounds());

    //big polygons need normal click apart from alomstOver
    TRP.layer.on('click', onClicker);

    function onClicker(e) {
      let object = e.layer.feature;
      // mymap.current.flyTo(object.geometry.coordinates.reverse(), 10)
      changePoint(object.properties.order)
    }

    changePoint(0)

    setMapInstance(mymap.current);

  }, []);

  useEffect(() => {

    if (!mapInstance) return;
    if (mapInstance) {
      if (point) {
        mapInstance.flyTo([point.geometry.coordinates[1], point.geometry.coordinates[0]], 10)
      } //mymap.current.fitBounds(TRP.layer.getBounds()); 
    }
  }, [mapInstance, point]);


    const getPoint = function(trp, tripId, number) {let a = trp.find((e) => {return e.properties.tripNo == tripId && e.properties.order == number}); return a ? a : undefined};
    const getPrevJourney = function(jrn, tripId, number) {let a = jrn.find((e) => {return e.properties.tripNo == tripId && e.properties.endId == number}); return a ? a.properties : undefined};
    const getNextJourney = function(jrn, tripId, number) {let a = jrn.find((e) => {return e.properties.tripNo == tripId && e.properties.beginId == number}); return a ? a.properties : undefined};
  
  const changePoint = (p) => {
    console.log(p)
    let aPoint, bPoint;

    const bJourney = getNextJourney(jrn.features, match.params.trip, p)
    if (bJourney) {bPoint = getPoint(trp.features, match.params.trip, bJourney.endId).properties.name}

    
    if (p == 0) {
      setPoint(undefined)
      setNeighbours([undefined, {...bJourney, name: bPoint}])

    } else if (p == 1) {
      setPoint(getPoint(trp.features, match.params.trip, p));
      console.log(getPoint(trp.features, match.params.trip, p))
      setNeighbours([undefined, {...bJourney, name: bPoint}])

    } else {
      setPoint(getPoint(trp.features, match.params.trip, p));
      console.log(getPoint(trp.features, match.params.trip, p))
      const aJourney = getPrevJourney(jrn.features, match.params.trip, p)
      if (aJourney) {aPoint = getPoint(trp.features, match.params.trip, aJourney.beginId).properties.name}
      if (bPoint) {
        setNeighbours([{...aJourney, name: aPoint}, {...bJourney, name: bPoint}])
      } else {
        setNeighbours([{...aJourney, name: aPoint}, undefined])      
      }
    }
  }


  return (
    <div ref={wrapper} id="map">
      {point ? <TripPoint point={point} neighbours={neighbours} changePoint={(e) => changePoint(e)}/>
      : <TripSummary tripId={match.params.trip} point={point} neighbours={neighbours} changePoint={(e) => changePoint(e)}/>}
      
    </div>
  );
}

export default TripMap;
