import React, {useState, useEffect} from "react";
import {Accordion, Card, Button} from 'react-bootstrap'
import dict from '../assets/data/dict.json'

const AccordionFull = ({data}) => {

    const [items, setItems] = useState([["C", "Warszawa", {citizens: 17500, district: "Mazowieckie"}]])
    const [attributes, setAttributes] = useState([])

    const compareName = (a, b) => {
        if (a[1] > b[1]) return 1
        if (a[1] < b[1]) return -1
    }

    const prepareData = (data) => {
        let res = []
        let features = data.features
        
        let propsNames = Object.assign({}, features[0].properties);
        ['name', 'id', 'lvlDiff'].forEach(e => {
        delete propsNames[e]
        })
        setAttributes(Object.keys(propsNames))

        features.map(feature => {
            let att = Object.assign({}, feature.properties);
            ['name', 'id', 'lvlDiff'].forEach(e => {
            delete att[e]
            })
            res.push([feature.properties.id, feature.properties.name, att])
        })

        return res.sort(compareName)
    }

    useEffect(() => {
        setItems(prepareData(data))
    }, [data]);
    
    return (
        <Accordion defaultActiveKey="0" style={{fontFamily: "Montserrat", fontStyle: "normal", color: "rgba(31,47,80,1)"}}>
            {items.map(item => {
                return (
                    <Card key={item[1]}>
                    <Card.Header style={{ backgroundColor: "rgb(244,244,247)"}}>
                    <Accordion.Toggle as={Button} variant="link" eventKey={item[1]} style={{color: "rgba(31,47,80,1)"}}>
                        {item[1]}
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={item[1]}>
                    <Card.Body>
                    {attributes.map( e => {
                        return(<div key={item[1]+e}> {dict[item[0]][e] + ": " + item[2][e]}</div>)})}
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                )})}
        </Accordion>
    )

}

const MenuItem = ({text, selected}) => {
    return (
        <div className={selected ? "menu-item active" : "menu-item"} style={{fontFamily: "Montserrat", fontStyle: "normal", color: "rgba(31,47,80,1)"}}>
            {text}
        </div>)};

const Arrow = ({ text, className }) => {
    return (
        <div className={className} style={{fontWeight: "bold", fontSize: "20px", 'color': "#33CC67"}}>
            {text}
        </div>
    )};

export {AccordionFull, MenuItem, Arrow}