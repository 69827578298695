import React, {useState, useEffect} from "react";
import dict from '../assets/data/dict.json'
import arrow from '../assets/images/arrow-down.png'
import c from '../assets/data/colors'

const Info = ({infoWord, infoExtended, setInfoExtended}) => {
    return (
      <div id="info" className="info" style = {normalInfo} onClick ={() => setInfoExtended(!infoExtended)}> 
        {infoWord}
        {infoExtended ? <img src={arrow} width="20" alt="logo" style = {{position: "absolute",bottom: 5, right: 15, transform: "rotate(180deg)"}}/>
        : <img src={arrow} width="20" alt="logo" style = {{position: "absolute",bottom: 5, right: 15}}/>}
      </div>
    );
 }

 const InfoGame = ({infoWord, style}) => {

  let infoStyle = null;

  if (style === "right")
      {infoStyle = {...normalInfo, color: c.green}}
  else if (style === "wrong")
      {infoStyle = {...normalInfo, color: c.red}}
  else 
      {infoStyle = {...normalInfo, color: c.naviblue}}

  return (
    <div id="info" className="info" style = {infoStyle}> 
      {infoWord}
    </div>
  );
}

 const InfoExtended = ({object}) => {

    const [detailsKeys, setDetailsKeys] = useState([])
  
    useEffect(() => {
        let obj = Object.assign({}, object);
        ['name', 'id', 'lvlDiff'].forEach(e => {
        delete obj[e]
        })
        let attributes = Object.keys(obj)
        attributes.forEach( (part, index, theArray) => {
            theArray[index] = [object.id, part]
        })
        setDetailsKeys(attributes)
    },[object])
  
      return (
        <div id="infoExtended" className="info" style = {infoStyle}>
          {detailsKeys.map( e => {
            return(
              <div key={e[1]}>{dict[e[0]][e[1]] + ": " + object[e[1]]}</div>
            )
          })}
        </div>
      );
   }

  export {Info, InfoGame, InfoExtended}

  const infoStyle = {
    ...c.whitebox,
    'height': 225,
    'marginTop': 15,
    'zIndex': 1000,
    ...c.cambayregular,
    'color': c.naviblue,
  }

const normalInfo = {
    ...c.whitebox,
    'height': 75,
    'marginTop': 15,
    'zIndex': 1000,
    ...c.cambaybold,
}