import React, {useState, useEffect} from "react";
import dict from '../assets/data/dict.json'
import arrow from '../assets/images/arrow-down.png'
import c from '../assets/data/colors'
import { db, storage } from '../firebaseConfig';
import { collection, getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faCircleRight } from '@fortawesome/free-regular-svg-icons'
import { faPlane, faBus, faTaxi, faTrain, faShip, faHouseChimney } from '@fortawesome/free-solid-svg-icons'

const transportIcon = (transport) => {
  const dict = {
    plane: faPlane,
    bus: faBus,
    taxi: faTaxi
  }
  let ar = transport.split('+')
  return ar.map(type => {return dict[type]})
}

const TripPoint = ({point, neighbours, changePoint}) => {

  const [images, setImages] = useState([])
    
  useEffect(() => {
    (async () => {
      const querySnapshot = await getDocs(collection(db, "images"))
      let imagesList = []
      let imagesList2 = []
      querySnapshot.forEach((doc) => {
        imagesList.push(doc.data().big_url)
      });

      for (const image of imagesList) {
        let spaceRef = ref(storage, image)
        const downloadURL = await getDownloadURL(spaceRef)
        imagesList2.push(downloadURL)
      }

      setImages(imagesList2)

    })();
  }, []);

    return (
      <div id="tripPoint" className="scrollbar scrollbar-primary" style = {{...normalInfo, overflow: "scroll"}}>
        {neighbours ? 
        <div id="navigation" style = {{fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
          <div id="prev" style = {{width: "48%", fontSize: 12, display: "flex", flexDirection: "row", alignItems: "center",  justifyContent: "center", backgroundColor: '#F4F4F7',  borderRadius: "5px"}} onClick={() => {return neighbours[0] ? changePoint(neighbours[0].beginId) : changePoint(0)}}>
          {neighbours[0] ? <>
          <div style = {{display: "flex", flexDirection: "column"}}>
          {transportIcon(neighbours[0].type).map(icon => 
            <FontAwesomeIcon key={icon.toString()} icon={icon} style={{height: 15, marginRight: 7}}/>  
          )}
          </div>
          <div style = {{display: "flex", flexDirection: "column",}}>
          <div style = {{fontSize: 16}}>{neighbours[0].name}</div>
          <div>{(neighbours[0].time / 60) + "h - " + (neighbours[0].length / 1000) + " km"}</div>
          </div></> :
          <>
            <div style = {{display: "flex", flexDirection: "column"}}>
              <FontAwesomeIcon icon={faHouseChimney} style={{height: 15, marginRight: 7}}/>  
            </div>
            <div style = {{display: "flex", flexDirection: "column",}}>
              <div style = {{fontSize: 16}}>{"Start"}</div>
            </div>
          </>}
          </div>
          <div id="next" style = {{width: "48%", fontSize: 12, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: '#F4F4F7', borderRadius: "5px"}}  onClick={() => {return neighbours[1] ? changePoint(neighbours[1].endId) : changePoint(0)}}>
          {neighbours[1] ? <>
          <div style = {{display: "flex", flexDirection: "column"}}>
          <div style = {{fontSize: 16}}>{neighbours[1].name}</div>
          <div>{(neighbours[1].time / 60) + "h - " + neighbours[1].length / 1000 + " km"}</div>
          </div>
          <div style = {{display: "flex", flexDirection: "column"}}>
          {transportIcon(neighbours[1].type).map(icon => 
            <FontAwesomeIcon key={icon.toString()} icon={icon} style={{height: 15, marginLeft: 7}}/>  
          )}
          </div>
          </> : null}
          </div>
        </div> : null}
        <div id="tripName">
          {point ? point.properties.name : ""}
        </div>
        <div id="tripDetails" style = {{fontSize: 12}}>
          <div>
            {point ? "Wyprawa: " + point.properties.tripName : ""}
          </div>
          <div>
            {point ? "Kraj: " + point.properties.Countries : ""}
          </div>
        </div>
        <div id="tripDescription">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginTop: "10px"}}>
            {"Opis"}
          </div>
          <div style = {{fontSize: 14}}>
            {point ? point.properties.dscrptn : ""}
          </div>
        </div>
        <div id="play" style={{fontSize: 14, backgroundColor: '#F4F4F7', padding: 10, margin: 10, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Link to={`/blog`} >
                {"Przejdź do informacji praktycznych"}
              </Link>           
            </div>
        <div id="photos">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginBottom: "15px"}}>
            {"Zdjęcia"}
        </div>
          <div style = {{fontSize: 14, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap"}}>
            {images.map(image =>
              <img key={image} src={image} style={{marginBottom: "10px", width: "40%"}} alt="photo"></img>
              )}
              {images.map(image =>
              <img key={"0"+image} src={image} style={{marginBottom: "10px", width: "40%"}} alt="photo"></img>
              )}
          </div>
        </div>
      </div>
    );
 }


export default TripPoint;

const normalInfo = {
    ...c.whitebox,
    // 'height': 525,
    'marginTop': 35,
    'marginLeft': 35,
    'zIndex': 1000,
    ...c.cambaybold,
    'color': c.naviblue,
}