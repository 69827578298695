import React from "react";
import c from "../assets/data/colors";

const Comments = () => {

return (
    <div id="game" style={commentsStyle}>
        <div className="parameterBox" style={parameterBoxStyle}>
            <br/>
            Proszę o ewentualne uwagi na maila:  bwiktorzak@gmail.com
            <br/>
            <br/>
            <br/>
            <br/>
            Zachęcam również do wypełnienia ankiety dotyczącej aplikacji.
            Czas rozwiązania to około 7 min.
            <br/>
            <br/>
            <iframe title="Google Forms" src="https://docs.google.com/forms/d/e/1FAIpQLScmJcCmnllULugfdGArX8ZhMb9KJyai4Gt8XRTjXdyLY6AYVA/viewform?embedded=true" width="100%" height="500" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>

        </div>    
    </div>
);
}

const commentsStyle = {
    width: "100%",
    height: "94vh",
    padding: 30,
    background: c.bluegray,
  }

const parameterBoxStyle = {
    ...c.whitebox,
    'height': 700,
    'marginTop': 30, 
}

export default Comments;