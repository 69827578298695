import React, {useState, useEffect} from "react";
import dict from '../assets/data/dict.json'
import arrow from '../assets/images/arrow-down.png'
import c from '../assets/data/colors'
import { db, storage } from '../firebaseConfig';
import { collection, getDocs, query, where } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";

const CountrySummary = ({information}) => {

  const [images, setImages] = useState([])
  const [trips, setTrips] = useState([])
    
  useEffect(() => {

    (async () => {
      const querySnapshotTrips = await getDocs(query(collection(db, "trips"), where("country", "==", information.iso3)))
      let tripsList = []
      querySnapshotTrips.forEach((doc) => {
        tripsList.push({name: doc.data().name, trip: doc.data().trip})
      });

      setTrips(tripsList)
    })();


    (async () => {
      const querySnapshot = await getDocs(collection(db, "images"))
      let imagesList = []
      let imagesList2 = []
      querySnapshot.forEach((doc) => {
        imagesList.push(doc.data().big_url)
      });

      for (const image of imagesList) {
        let spaceRef = ref(storage, image)
        const downloadURL = await getDownloadURL(spaceRef)
        imagesList2.push(downloadURL)
      }

      setImages(imagesList2)

    })();
  }, [information]);

    return (
      <div id="countrySummary" className="scrollbar scrollbar-primary" style = {{...normalInfo, overflow: "scroll"}}>
        <div id="countryName" style={{marginBottom: "5px"}}>
          {information.name_pl ? information.name_pl : ""}
        </div>
        <div id="countryDetails" style = {{fontSize: 12, display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
          <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "100%"}}>
          <div>
            {information.language ? "Język: " + information.language : ""}
          </div>
          <div>
            {information.currency ? "Waluta: " + information.currency : ""}
          </div>
          </div>
          <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "100%"}}>
        
          <div>
            {information.iso3 ? "Czas: " + information.iso3 : ""}
          </div>
          <div>
            {information.visa ? "Wiza: tak" : "Wiza: nie"}
          </div>
          </div>
        </div>
        <div id="countryDescription">
          <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginTop: "10px"}}>
            {"Opis"}
          </div>
          <div style = {{fontSize: 14}}>
            {"Kazachstan to kraj byłego ZSRR. W miastach widać wpływ XX wiecznego komunizmu i rosyjskiej dominacji. Kraj leżący wgłębi kontynentu warto odwiedzić ze względu na walory krajoznawcze. Piękne góry, kaniony i niezmierzone wzrkiem stepy. Jest to kraj bardzo sympatycznych i gościnnyc ludzi."}
          </div>
        </div>
        <div id="countryTrips">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginTop: "10px"}}>
            {"Wycieczki"}
          </div>
          <div style = {{fontSize: 14}}>
          {trips.map(trip =>
            <div id="play" style={{backgroundColor: '#F4F4F7', padding: 10, margin: 10, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Link to={`/trip/${trip.trip}`} >
                {trip.name}
              </Link>           
            </div>
          )}
          </div>
        </div>
        <div id="photos">
        <div  style = {{fontSize: 20, display: "flex", flexDirection: "row", alignItems: "start", marginTop: "20px"}}>
            {"Zdjęcia"}
        </div>
          <div style = {{fontSize: 14, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap"}}>
            {images.map(image =>
              <img src={image} style={{marginBottom: "10px", width: "40%"}} alt="photo"></img>
              )}
          </div>
        </div>
      </div>
    );
 }


export default CountrySummary;

const normalInfo = {
    ...c.whitebox,
    // 'height': 525,
    'marginTop': 35,
    'marginLeft': 35,
    'zIndex': 1000,
    ...c.cambaybold,
    'color': c.naviblue,
}