import React, {useState, useEffect, useRef} from "react";
import L from "leaflet";
import gsap from 'gsap';
import * as FS from '../plugins/Leaflet.fullscreen/dist/Leaflet.fullscreen.js';
import * as IL from '../plugins/LayersControl/iconLayers.js';
import autoIcon from '../plugins/LayersControl/icons/auto.png';
import sattIcon from '../plugins/LayersControl/icons/satt.png';
import hipsIcon from '../plugins/LayersControl/icons/hipso.png';
import osmIcon from '../plugins/LayersControl/icons/osm.png';
import * as AO from '../plugins/leaflet.almostover.js';
import * as LS from '../plugins/Search/leaflet-search.js';
import rvr from '../assets/geodata/rvr.json';
import cts from '../assets/geodata/cts.json';
import lks from '../assets/geodata/lks.json';
import mnt from '../assets/geodata/mnt.json';
import prk from '../assets/geodata/prk.json';
import uns from '../assets/geodata/uns.json';
import mzr from '../assets/geodata/mzr.json';
import lnd from '../assets/geodata/lnd.json';
import glg from '../assets/geodata/glg.json';
import dst from '../assets/geodata/dst.json';
import cnt from '../assets/geodata/cnt.json';
import CityIcon from '../assets/images/landscapes/cityscape.png';
import UnescoIcon from '../assets/images/landscapes/castle.png';
import {Info, InfoExtended} from '../components/Info';

//function contains layer name, data, style
function Issue(name, namePL, id, source, qst, style) {
  this.name = name;
  this.namePL = namePL
  this.id = id;
  this.source = source;
  this.layer = L.geoJson(this.source, {pointToLayer: function PToL(feature, latlng) {return L.marker(latlng, style)}})
  this.qst = qst;
  this.style = style;
  this.setStyle = function() {return this.layer.setStyle(this.style)};
}

const Map = () => {

  //wrapper used for gsap
  const wrapper = useRef(null);

  const [name, setName] = useState({'name': "Kliknij w obiekt, aby zobaczyć nazwę!", 'id': "", 'lvlDiff': ""})
  const [infoExtended, setInfoExtended] = useState(false)

  useEffect(() => {

    //animations
    const info = wrapper.current.children.info
    //const infoExtended = wrapper.current.children.question

    gsap.set([info/*, infoExtended*/], {autoAlpha: 0});

    const tl = gsap.timeline({defaults: {ease: 'power3.inOut'}})

    tl.fromTo(info, {y: '-=50'}, {duration: 2, y: '+=50', autoAlpha: 1})

    //map init
    let mymap = L.map('map').setView([57.947609, 19.157056], 6, {zoomControl: true });
    //maps max&min zoom levels
    mymap.options.minZoom = 5;
    mymap.options.maxZoom = 10;  
    mymap.setMaxBounds([[46.000, 11.000], [57.000, 27.000]])

    //set first basemap - init one
    //var OSM = L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvpr8pv1sk719pahw7y64h7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA').addTo(mymap)
    //basemaps which can be choosen
    var providers = {};
    providers['Default'] = {
        title: 'auto',
        icon: autoIcon,
        layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvpr8pv1sk719pahw7y64h7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
            maxZoom: 20,
        })
    };
    providers['Hipso'] = {
      title: 'hipso',
      icon: hipsIcon,
      layer: L.tileLayer.wms('https://ows.terrestris.de/osm/service?', {
        layers: 'TOPO-WMS'
      })
    };providers['Sattelite'] = {
      title: 'satka',
      icon: sattIcon,
      layer: L.tileLayer('https://api.mapbox.com/styles/v1/bwiktorz/ckdvsgxi02g4d19lli0o110t2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYndpa3RvcnoiLCJhIjoiY2p0OGh6OWtuMDhmNDN5cXk4ZTEzMDltYiJ9.UVVfRCnZuTePUEoenfBOKA', {
          maxZoom: 20,
      })
    };
    providers['OSM'] = {
      title: 'osm',
      icon: osmIcon,
      layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
      })
    };


    //set basemap control - insert basemaps and add control
    var layers = [];
    for (let providerId in providers) {
      layers.push(providers[providerId]);
    }
    

    //layers features
    let City = L.icon({
      iconUrl: CityIcon,
      iconSize:     [30, 30],
      iconAnchor:   [20, 20],
      popupAnchor:  [-30, -76]
    });

    let UNESCO = L.icon({
      iconUrl: UnescoIcon,
      iconSize:     [30, 30],
      iconAnchor:   [20, 20],
      popupAnchor:  [-30, -76]
    });

    //insert needed layers with data -->add issue 1/3
    var CTS = new Issue("CTS", "Miasta", "C", cts, 0, {icon: City})
    var DST = new Issue("DST", "Województwa", "D", dst, 1, {weight : 2, color : '#f7be5c'}); DST.setStyle();
    var GLG = new Issue("GLG", "Zagłębia", "G", glg, 2, {weight : 2, color : '#a83254'}); GLG.setStyle();
    var LKS = new Issue("LKS", "Jeziora", "L", lks, 3, {weight : 2, color : '#224fa3'}); LKS.setStyle();
    var MNT = new Issue("MNT", "Góry", "M", mnt, 4, {weight : 2, color : '#6b4738'}); MNT.setStyle();
    var LND = new Issue("LND", "Krainy", "N", lnd, 9, {weight : 2, color : '#38636b'}); LND.setStyle();
    var PRK = new Issue("PRK", "Parki", "P", prk, 5, {weight : 2, color : '#386b3a'}); PRK.setStyle();
    var RVR = new Issue("RVR", "Rzeki", "R", rvr, 6, {weight : 2, color : '#4dc3ff'}); RVR.setStyle();
    var UNS = new Issue("UNS", "UNESCO", "U", uns, 7, {icon :  UNESCO});
    var MZR = new Issue("MZR", "Mazury", "Z", mzr, 8, {weight : 2, color : '#6a386b'}); MZR.setStyle();

    //push Issues to array -->add issue 2/3
    var issues = [];
    issues.push(CTS)
    issues.push(DST)
    issues.push(GLG)
    issues.push(LKS)
    issues.push(MNT)
    issues.push(LND)
    issues.push(PRK)
    issues.push(RVR)
    issues.push(UNS)
    issues.push(MZR)

    var issuesLayers = [];
    for (let i = 0; i < issues.length; i++) {
      issuesLayers.push(issues[i].layer)
    }

    //push Issues layers to dictionary -->add issue 3/3
    var overlays = {
      
      "Rzeki": RVR.layer,
      "Jeziora": LKS.layer,
      "Góry": MNT.layer,
      "Parki": PRK.layer,
      "UNESCO": UNS.layer,
      "Krainy": LND.layer,
      "Mazury": MZR.layer,
      "Zagłębia": GLG.layer,
      "Województwa": DST.layer,
      "Miasta": CTS.layer,
    }

    var allLayers = L.featureGroup(issuesLayers);

    //fullscreen control
    mymap.addControl(new L.Control.Fullscreen({position: 'bottomright'}));

    //zoom control
    mymap.zoomControl.setPosition('bottomright');

    L.control.layers(overlays, null, {collapsed: false, position: 'bottomleft'}).addTo(mymap);

    //search control - allows to find object by name and zoom it
    var search = new L.Control.Search({
      position:'bottomright',
      layer: allLayers,
      propertyName: 'name',
      initial: false,
      zoom: 12,
      marker: false
    });
    mymap.addControl(search);
    
    mymap.eachLayer(function (layer) {
      mymap.removeLayer(layer);
    });

    mymap.addControl(new L.control.IconLayers(layers));

    //set suitable zoom level
    mymap.fitBounds(allLayers.getBounds());

    //big polygons need normal click apart from alomstOver
    overlays["Województwa"].on('click', onClicker);
    overlays["Krainy"].on('click', onClicker);
    overlays["Zagłębia"].on('click', onClicker);
    overlays["Miasta"].on('click', onClicker);

    function onClicker(e) {
      let object = e.layer.feature.properties;
      setName(object);
      console.log("onClicker")
    }

    //almostOver
    var layerInAlmostOver = overlays["Miasta"];
    mymap.almostOver.addLayer(layerInAlmostOver)

    //add or remove alostOver layer depends on control
    mymap.on('baselayerchange', function(ev) {
      mymap.almostOver.removeLayer(layerInAlmostOver);
      layerInAlmostOver = ev.layer;
      mymap.almostOver.addLayer(ev.layer)
    });

    mymap.on('almost:click', function (e) {
      let object = e.layer.feature.properties;
      setName(object);
      console.log("almostClick")
    })

    //outline of Poland
    var contour = L.geoJson(cnt).addTo(mymap);
    contour.setStyle({weight : 4, color : '#000000', fillOpacity: 0})
    contour.bringToBack()
  }, []);

  const changeInfoExtended = (condition) => {
    setInfoExtended(condition)
  }

  return (
    <div ref={wrapper} id="map">
      <Info infoWord={name.name} infoExtended={infoExtended}  setInfoExtended={(e) => changeInfoExtended(e)}/>
      {infoExtended ? <InfoExtended object={name}/> : null}
    </div>
  );
}

export default Map;
