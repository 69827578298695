import React, {useState} from "react";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import c from "../assets/data/colors";
import I from "../assets/images/I.PNG";
import II from "../assets/images/II.PNG";
import III from "../assets/images/III.PNG";
import IV from "../assets/images/IV.PNG";
import V from "../assets/images/V.PNG";
import close from "../assets/images/close.png";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

const Game = () => {

const settingsDict = {
    difficultyLevel: "POZIOM TRUDNOŚCI",
    answerTime: "CZAS NA ODPOWIEDŹ",
    amountOfQuestions: "LICZBA PYTAŃ",
    category: "KATEGORIA",
    details: "DODATKOWE PYTANIA",
}

const [manualIsOpen, setManualIsOpen] = useState(false)

const [settings, setSettings] = useState(
    {
        difficultyLevel: {
            easy: {
                id: "easy",
                name: "ŁATWY",
                image: require('../assets/images/landscapes/fields-1.png'),
                selected: false,
            },
            medium: {
                id: "medium",
                name: "ŚREDNI",
                image: require('../assets/images/landscapes/hills.png'),
                selected: true,
            },
            difficult: {
                id: "difficult",
                name: "TRUDNY",
                image: require('../assets/images/landscapes/mountains-1.png'),
                selected: false,
            }
        },
        answerTime: {
            "10": {
                id: "10",
                name: "10s",
                image: false,
                selected: false,
            },
            "30": {
                id: "30",
                name: "30s",
                image: false,
                selected: true,
            },
            "60": {
                id: "60",
                name: "60s",
                image: false,
                selected: false,
            }          
        },
        amountOfQuestions: {
            "10": {
                id: "10",
                name: "10",
                image: false,
                selected: true,
            },
            "all": {
                id: "all",
                name: "ALL",
                image: false,
                selected: false,
            }
        },
        category: {
            RVR: {
                id: "RVR",
                name: "RZEKI",
                image: require('../assets/images/landscapes/river.png'),
                selected: true,
            },
            CTS: {
                id: "CTS",
                name: "MIASTA",
                image: require('../assets/images/landscapes/cityscape.png'),
                selected: false,
            },
            MNT: {
                id: "MNT",
                name: "GÓRY",
                image: require('../assets/images/landscapes/mountains.png'),
                selected: false,
            },
            UNS: {
                id: "UNS",
                name: "OBIEKTY UNESCO",
                image: require('../assets/images/landscapes/castle.png'),
                selected: false,
            },
            LKS: {
                id: "LKS",
                name: "JEZIORA",
                image: require('../assets/images/landscapes/waterfall-1.png'),
                selected: false,
            },
            PRK: {
                id: "PRK",
                name: "PARKI NARODOWE",
                image: require('../assets/images/landscapes/trees.png'),
                selected: false,
            },
            MZR: {
                id: "MZR",
                name: "MAZURY",
                image: require('../assets/images/landscapes/sea.png'),
                selected: false,
            },
            DST: {
                id: "DST",
                name: "WOJEWÓDZTWA",
                image: require('../assets/images/landscapes/village.png'),
                selected: false,
            },
            GLG: {
                id: "GLG",
                name: "ZAGŁĘBIA",
                image: require('../assets/images/landscapes/fields.png'),
                selected: false,
            },
            LND: {
                id: "LND",
                name: "KRAINY",
                image: require('../assets/images/landscapes/mill.png'),
                selected: false,
            },
            WCT: {
                id: "WCT",
                name: "MIASTA ŚWIATA (BETA)",
                image: require('../assets/images/landscapes/city.png'),
                selected: false,
            },
            EUR: {
                id: "EUR",
                name: "EUROPA (BETA)",
                image: require('../assets/images/landscapes/europe.png'),
                selected: false,
            },
            WRD: {
                id: "WRD",
                name: "ŚWIAT (BETA)",
                image: require('../assets/images/landscapes/earth-globe.png'),
                selected: false,
            }
        },
        details: {
            yes: {
                id: "yes",
                name: "TAK",
                image: false,
                selected: false,
            },
            no: {
                id: "no",
                name: "NIE",
                image: false,
                selected: true,
            }
        }
    }
);

const [toSend, setToSend] = useState({
    difficultyLevel: "medium",
    answerTime: "30",
    amountOfQuestions: "10",
    category: "RVR",
    details: "no",
});

const onIconClick = (event, settingsPart) => {

    let newSettings = Object.assign({}, settings);
    for (let selection in newSettings[settingsPart]) {     
        newSettings[settingsPart][selection].selected = false;
    }       
    newSettings[settingsPart][event.target.id]['selected'] = true;
    setSettings(newSettings);

    let newToSend = Object.assign({}, toSend);
    newToSend[settingsPart] = event.target.id
    setToSend(newToSend)

}

const sendStartEvent = (a, b,c, d, e) => {
    logEvent(analytics, 'startGame', {
        difficultyLevel: a,
        answerTime: b,
        amountOfQuestions: c,
        category: d,
        details: e
    })
}

Modal.setAppElement('body')

return (
        <div id="game" style={gameStyle}>
         <Modal
          isOpen={manualIsOpen}
          onRequestClose={() => setManualIsOpen(false)}
          contentLabel="Maunual"
        >
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img src={close} alt="close" style={{position: "absolute", top: 15, right: 15, width: "5vw"}} onClick={() => setManualIsOpen(false)}/>
                <img src={V} alt="aim" style={{width: "75vw"}}/>
                <img src={I} alt="settings" style={{width: "75vw"}}/>
                <img src={II} alt="view" style={{width: "75vw"}}/>
                <img src={III} alt="click" style={{width: "75vw"}}/>
                <img src={IV} alt="results" style={{width: "75vw"}}/>
            </div>
        </Modal>
            {Object.keys(settings).map(frame => (
                <div key={frame} className="parameterBox" style={parameterBoxStyle}>
                    <div key={frame + "1"} style={parameterNameStyle}>
                        {settingsDict[frame]}
                    </div>
                    <div key={frame + "2"} style={parameterOptionsStyle}>
                        {Object.keys(settings[frame]).map(icon => (
                            <div key={icon}>
                                {settings[frame][icon]['image'] !== false ? <img
                                    id={settings[frame][icon]['id']}
                                    src={settings[frame][icon]['image']}
                                    alt={settings[frame][icon]['id']}
                                    style={settings[frame][icon]['selected'] ? settingsPickedStyle : settingsPickStyle}
                                    onClick={(e) => onIconClick(e, frame)} /> : <div
                                    id={settings[frame][icon]['id']}
                                    style={settings[frame][icon]['selected'] ? settingsPicked2Style : settingsPick2Style}
                                    onClick={(e) => onIconClick(e, frame)}>{settings[frame][icon]['name']}</div>}
                                {settings[frame][icon]['image'] !== false ? <p
                                    style = {settings[frame][icon]['selected'] ? {...settingsTextPickStyle, 'fontSize': 14} : {...settingsTextPickStyle, 'fontSize': 12}}>
                                {settings[frame][icon]['name']} </p> : null}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <div
              className="parameterBox"
              style={parameterBoxStyle}
              onClick={() => setManualIsOpen(true)}>
              <h1 style={{textAlign: "center", fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "semibold", fontSize: "20px"}}> JAK GRAĆ ? </h1>
              <h1 style={{textAlign: "center", fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "semibold", fontSize: "10px"}}> (kliknij tutaj) </h1>
            </div> 
            <div id="play" style={{backgroundColor: '#F4F4F7', padding: 10, margin: 10, display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Link to={`/game/${toSend.difficultyLevel}/${toSend.answerTime}/${toSend.amountOfQuestions}/${toSend.category}/${toSend.details}`} >
                    <div id="playButton" style={playButtonStyle} onClick={() => sendStartEvent(toSend.difficultyLevel, toSend.answerTime, toSend.amountOfQuestions, toSend.category, toSend.details)}>
                     GRAJ
                    </div>
                </Link>           
            </div>
            <div style={{fontSize: 5,}}>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>           
        </div>
    );
}

const settingsPick2Style = {
    'height': 50,
    'width': 50,
    'borderRadius': 25,
    'border': false,
    'paddingTop': 12,
    'background': c.bluegray,
    'filter': c.shadow,
    textAlign: "center",
    ...c.montserratbold,
    fontSize: "20px",
    'color': c.naviblue,
}

const settingsPicked2Style = {
    ...settingsPick2Style,
    'background': "linear-gradient(90deg, #33CC67 0%, #5BC1AF 100%)",
    'filter': c.shadowgreen,
    'color': c.white,
}


const settingsPickStyle = {
    height: 50,
    border: false,
    'marginBottom': 10,
    'filter': c.shadow,
}

const settingsPickedStyle = {
    'height': 50,
    'borderRadius': 25,
    'border': "solid 3px rgb(51,204,103)",
    'marginBottom': 10,
    'filter': c.shadowgreen,
}

const gameStyle = {
    width: "100%",
    padding: 10,
    background: c.bluegray
}

const parameterBoxStyle = {
    ...c.whitebox,
    'marginTop': 7,  
    'marginBottom': 15,
}

const parameterNameStyle = {
    'backgroundColor': c.white,
    'margin': 10,
    ...c.montserratbold,
	'fontSize': 20,
    'color': c.naviblue,
}

const parameterOptionsStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-evenly",
    'backgroundColor': c.white,
    //padding: 10,
    margin: 10,
    border: false,    
}

const playButtonStyle = {
    'width': 150,
    'height': 80,
    'backgroundColor': c.white,
    'padding': 20,
    'borderRadius': 50,
    'border': false,
    'filter': c.shadow,
    textAlign: "center",
    ...c.montserratbold,
	'fontSize': 30,
    'color': c.naviblue,
}

const settingsTextPickStyle = {
    'width': 125,
    'height': 25,
    'textAlign': "center",
    ...c.cambayregular,
	'fontSize': 12,
    'color': c.naviblue,
}

export default Game;