// import * as firebase from "firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


export const firebaseConfig = {
  apiKey: "AIzaSyAhaVkrKtXppGXGmjeEABx3m9zalf4Oa_c",
  authDomain: "geoquiz-18d0f.firebaseapp.com",
  databaseURL: "https://geoquiz-18d0f.firebaseio.com",
  projectId: "geoquiz-18d0f",
  storageBucket: "geoquiz-18d0f.appspot.com",
  messagingSenderId: "272801496226",
  appId: "1:272801496226:web:9831104ad336f62b8a2bfb",
  measurementId: "G-M4NDY4B3Z7"
}

const app = initializeApp(firebaseConfig)

const analytics  = getAnalytics()

const db = getFirestore(app);

const storage = getStorage(app);

export { analytics , db , storage }
