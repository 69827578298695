import React, {useState} from "react";
import '../index.css';
import c from "../assets/data/colors";

const Question = ({question, answers, answerQuestion}) => {

  const answerClick = (event, answer) => {
      //console.log(event)
      if (answers[answers[3]] === answer) {
        setPickedRight(true)
        setTimeout(() => answerQuestion("rightAnswers"), 500);
      }       
      else {
        setPickedRight(true)
        setTimeout(() => answerQuestion("wrongAnswers"), 500);
      }
        
  }

  const realAnswers = [answers[0],answers[1],answers[2]]
  const [pickedRight, setPickedRight] = useState(false)

    
  return (
    <div id="question" style={questionStyle}>
      <div id="enquiry" style={enquiryStyle}>
          {question[0] + " " + question[1] + "?"}
      </div>
      {realAnswers.map(answer => (
          <div key={answer}
                style={(pickedRight && answers[answers[3]] === answer) ? answerRightStyle : answerStyle}
                onClick={(e) => answerClick(e,answer)}>
              {answer}
          </div>
      ))}
    </div>
  );
}
  
  export default Question;

const questionStyle = {
  ...c.whitebox,
  'marginTop': 7,
  'zIndex': 2000,
}

const enquiryStyle = {
  'backgroundColor': c.white,
  'margin': 10,
  ...c.montserratbold,
	'fontSize': 20,
  'color': c.naviblue,
  
}

const answerStyle = {
  //'height': 60,
  'backgroundColor': c.bluegray,
  'padding': 20,
  'margin': 20,
  'borderRadius': 30,
  'filter': c.shadow,
  ...c.cambayregular,
	'fontSize': 18,
  'color': c.naviblue, 
}

const answerRightStyle = {
  //'height': 60,
  'backgroundColor': c.green,
  'padding': 20,
  'margin': 20,
  'borderRadius': 30,
  'filter': c.shadow,
  ...c.cambayregular,
	'fontSize': 18,
  'color': c.naviblue, 
}